import { Id } from './common';
import { RuleGroupType } from 'react-querybuilder';

export enum DestinationTypeEnum {
  target_page = 'target_page',
  next_step = 'next_step',
  menu = 'menu',
}

export interface ActionRule {
  id?: number;
  action_id?: number | string;
  name?: string;
  execution_order?: number;
  target_page_id?: number | null;
  schema?: RuleGroupType | null;
  destination?: DestinationTypeEnum;
}

export class ActionRuleClass implements ActionRule {
  id = 0;
  action_id = 0;
  name = '';
  execution_order = undefined;
  target_page_id = null;
  schema = null;
  destination = undefined;
}

export interface PageAction {
  id?: number;
  page_id?: number;
  name?: string;
  action_type?: string;
  action_meta?: { [x: string]: unknown };
  default_target_page_id?: number | null;
  order?: number;
  rules?: ActionRule[];
  destination?: DestinationTypeEnum;
}

export class PageActionClass implements PageAction {
  id = 0;
  page_id = 0;
  name = '';
  action_type = 'button';
  action_meta = undefined;
  default_target_page_id = undefined;
  order = 0;
  rules = [];
  destination = undefined;
}

export interface PostPageAction extends Omit<PageAction, 'rules'> {}

export interface GetActionRule extends Id {
  action_id: number | string;
}

export const ACTION_TYPES = ['button', 'skip', 'auto'] as const;

export type ActionType = (typeof ACTION_TYPES)[number];

export const ENTITY_TYPES = [
  'user',
  'child',
  'partner',
  'family',
  'property',
  'vehicle',
] as const;

export type EntityType = (typeof ENTITY_TYPES)[number];
