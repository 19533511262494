import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  removeNullishOrEmptyFields,
  postQuestionCallback,
  putQuestionCallback,
  questionState,
  QuestionClass,
  getQuestionByIdCallback,
  getAllQuestionCallback,
  questionLoaderState,
  QUESTION_TYPES,
  getAllDomainKeysCallback,
  domainKeysState,
  domainKeysLoaderState,
  VALIDATION_TEXT,
  VALIDATION_DATE,
  ENTITY_TYPES,
  questionsState,
  getAttributesMetaByEntityTypeCallback,
  attributesMetaLoaderState,
  attributesMetaState,
  OptionType,
  getParentDomainCallback,
  EntityType,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import {
  LBTLabel,
  LBTSelect,
  LBTSpacer,
  LBTTextField,
} from '@laborability/components';
import { useParams } from 'react-router-dom';
import { LABEL_ENTITY_TYPE } from './PageAction';

const LABEL_QUESTION_TYPE = {
  boolean: 'Vero/falso',
  checkbox: 'Checkbox',
  date: 'Data',
  radio: 'Radio',
  select: 'Select',
  switch: 'Switch',
  text: 'Text',
};

const LABEL_VALIDATION = {
  alphabetic: 'Alfabetico',
  numberic: 'Numerico',
  alphanumeric: 'Alfanumerico',
  uppercase: 'Maiuscolo',
  lowercase: 'Minuscolo',
  past: 'Passata',
  past_today: 'Passata e odierna',
  future: 'Futura',
  future_today: 'Futura e odierna',
};

const QUESTION_INPUTS = {
  text: ['validation'],
  switch: [],
  select: [
    'options',
    'is_multiple',
    'has_option_description',
    'has_autocomplete',
  ],
  radio: ['options'],
  checkbox: ['options'],
  date: ['validation'],
  boolean: [],
};

interface Props {
  id?: number;
  onClose?: () => void;
}

export function QuestionForm({ id, onClose }: Props) {
  const { id: page_id } = useParams();
  const postQuestion = useRecoilCallback(postQuestionCallback, []);
  const putQuestion = useRecoilCallback(putQuestionCallback, []);
  const getQuestion = useRecoilCallback(getQuestionByIdCallback, []);
  const getAllQuestion = useRecoilCallback(getAllQuestionCallback, []);
  const getAllDomainKeys = useRecoilCallback(getAllDomainKeysCallback, []);
  const getParentDomain = useRecoilCallback(getParentDomainCallback, []);
  const getAllAttributes = useRecoilCallback(
    getAttributesMetaByEntityTypeCallback,
    [],
  );
  const [current, setCurrent] = useRecoilState(questionState);
  const [filterLoading, setFilterLoading] = useState(false);
  const domainKeys = useRecoilValue(domainKeysState);
  const allQuestions = useRecoilValue(questionsState);
  const attributesMeta = useRecoilValue(attributesMetaState);
  const questionLoader = useRecoilValue(questionLoaderState);
  const domainKeysLoader = useRecoilValue(domainKeysLoaderState);
  const attributesLoader = useRecoilValue(attributesMetaLoaderState);
  const isLoading =
    questionLoader || domainKeysLoader || attributesLoader || filterLoading;

  const resetFormState = () => {
    setCurrent({
      ...new QuestionClass(),
      display_order: allQuestions.length + 1,
      entity_type: undefined,
    });
  };

  useEffect(() => {
    getAllDomainKeys({});
    getAllAttributes({ entity_type: 'all' });
  }, []);

  useEffect(() => {
    if (id) getQuestion({ id });
    else resetFormState();
  }, [id]);

  if (!page_id) return null;

  return (
    <ModalForm
      defaultValues={{
        ...new QuestionClass(),
        display_order: allQuestions.length + 1,
        entity_type: undefined,
      }}
      isLoading={isLoading}
      currentValues={{
        ...current,
        entity_type: attributesMeta.find(
          item => item.id === current.attribute_meta_id,
        )?.entity_type as EntityType,
      }}
      validation={values => {
        return {
          ...(values.title === '' ? { title: 'Il nome è obbligatorio' } : {}),
        };
      }}
      handleSubmit={async values => {
        delete values.entity_type;
        if (values.id) {
          const res = await putQuestion({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
            page_id: Number(page_id),
          });
          if (res?.data) onClose?.();
          return;
        }
        const res = await postQuestion({
          ...removeNullishOrEmptyFields(values),
          page_id: Number(page_id),
        });
        if (res?.data) onClose?.();
        return;
      }}
      resetGridState={() => getAllQuestion({ id: Number(page_id) })}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleBlur, setFieldValue }) => {
        const questionInput = values.question_type
          ? QUESTION_INPUTS[
              values.question_type as keyof typeof QUESTION_INPUTS
            ]
          : [];
        return (
          <>
            <Grid item mobile={12}>
              <LBTTextField
                label="Nome"
                type="text"
                name="title"
                onChange={value => setFieldValue('title', value)}
                onBlur={handleBlur}
                value={values.title}
                required
                error={!!errors?.title}
                helperText={errors?.title}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTTextField
                label="Descrizione"
                type="text"
                name="description"
                onChange={value => setFieldValue('description', value)}
                onBlur={handleBlur}
                value={values.description}
                error={!!errors?.description}
                helperText={errors?.description}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="display_order-select"
                name="display_order"
                value={values.display_order}
                label="Ordine di visualizzazione"
                handleChange={e => setFieldValue('display_order', e)}
                onBlur={handleBlur}
                fullWidth
                required
                items={Array.from(
                  Array(allQuestions.length + (values.id ? 0 : 1)).keys(),
                ).map(i => ({ id: i + 1, name: i + 1 }))}
                error={!!errors?.display_order}
                helperText={errors?.display_order}
              />
            </Grid>
            <Grid item mobile={3}>
              <LBTSelect
                id="entity_type-select"
                name="entity_type"
                value={values.entity_type}
                label="Tipo entità"
                handleChange={e => {
                  setFieldValue('attribute_meta_id', undefined);
                  setFieldValue('entity_type', e);
                }}
                onBlur={handleBlur}
                fullWidth
                items={ENTITY_TYPES.map(item => ({
                  id: item,
                  name: LABEL_ENTITY_TYPE[item],
                }))}
                sx={{ minWidth: 0 }}
                error={!!errors?.entity_type}
                helperText={errors?.entity_type}
              />
            </Grid>
            <Grid item mobile={9}>
              <LBTSelect
                id="attribute_meta_id-select"
                name="attribute_meta_id"
                value={values.attribute_meta_id}
                label="Attributo"
                handleChange={e => setFieldValue('attribute_meta_id', e)}
                onBlur={handleBlur}
                fullWidth
                disabled={!values.entity_type}
                items={attributesMeta
                  .filter(item => item.entity_type === values.entity_type)
                  .map(item => ({
                    id: item.id as number,
                    name: item.attribute_name as string,
                  }))}
                error={!!errors?.attribute_meta_id}
                helperText={errors?.attribute_meta_id}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTTextField
                label="Suggerimento"
                type="text"
                name="hint"
                onChange={value => setFieldValue('hint', value)}
                onBlur={handleBlur}
                value={values.hint}
                error={!!errors?.hint}
                helperText={errors?.hint}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="required-select"
                name="required"
                value={Number(values.required)}
                label="Valore richiesto"
                items={[
                  { id: 1, name: 'Sì' },
                  { id: 0, name: 'No' },
                ]}
                handleChange={e => {
                  setFieldValue('required', !!e);
                }}
                required
                onBlur={handleBlur}
                error={!!errors?.required}
                helperText={errors?.required}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="question_type"
                name="question_type"
                value={values.question_type}
                label="Tipo"
                required
                items={QUESTION_TYPES.map(type => ({
                  id: type,
                  name: LABEL_QUESTION_TYPE[type],
                }))}
                handleChange={e => setFieldValue('question_type', e)}
                onBlur={handleBlur}
                error={!!errors?.question_type}
                helperText={errors?.question_type}
              />
            </Grid>
            {!!questionInput.length && (
              <>
                <Grid item mobile={12}>
                  <LBTSpacer spacing={1} />
                  <LBTLabel variant="sourceSubtitle" textAlign="left">
                    Dettagli domanda
                  </LBTLabel>
                </Grid>
                {questionInput.map(input => {
                  if (input === 'options')
                    return (
                      <>
                        <Grid item mobile={3}>
                          <LBTSelect
                            id="option_type-select"
                            name="option_type"
                            value={values.question_meta?.option_type}
                            label="Tipologia opzioni"
                            items={[
                              {
                                id: OptionType.domain,
                                name: 'Dominio',
                              },
                              {
                                id: OptionType.entity,
                                name: 'Entità',
                              },
                            ]}
                            handleChange={e =>
                              setFieldValue('question_meta.option_type', e)
                            }
                            onBlur={handleBlur}
                            required
                            sx={{ minWidth: 0 }}
                            error={
                              !!(errors?.question_meta as any)?.option_type
                            }
                            helperText={
                              (errors?.question_meta as any)?.option_type
                            }
                          />
                        </Grid>
                        <Grid item mobile={9}>
                          <LBTSelect
                            id="options-select"
                            name="options"
                            value={values.question_meta?.options}
                            label="Opzioni"
                            items={
                              values.question_meta?.option_type === 'domain'
                                ? domainKeys.map(item => ({
                                    id: item.domain,
                                    name: item.domain,
                                  })) ?? []
                                : ENTITY_TYPES.map(item => ({
                                    id: item,
                                    name: LABEL_ENTITY_TYPE[item],
                                  }))
                            }
                            handleChange={async e => {
                              setFieldValue('question_meta.options', e);
                              if (
                                values.question_meta?.option_type ===
                                OptionType.domain
                              ) {
                                setFilterLoading(true);
                                const res = await getParentDomain({
                                  id: e,
                                });
                                if (!res?.data) {
                                  setFieldValue(
                                    'question_meta.option_filter',
                                    undefined,
                                  );
                                  return setFilterLoading(false);
                                }

                                console.log({ data: res.data });
                                setFieldValue(
                                  'question_meta.option_filter',
                                  res.data,
                                );
                                setFilterLoading(false);
                              }
                            }}
                            onBlur={handleBlur}
                            required
                            error={!!(errors?.question_meta as any)?.options}
                            helperText={(errors?.question_meta as any)?.options}
                          />
                        </Grid>
                        {values.question_meta?.option_type ===
                          OptionType.domain && (
                          <Grid item mobile={12}>
                            <LBTSelect
                              id="options-filter-select"
                              name="option_filter"
                              value={values.question_meta?.option_filter}
                              label="Filtra per"
                              disabled
                              items={
                                domainKeys.map(item => ({
                                  id: item.domain,
                                  name: item.domain,
                                })) ?? []
                              }
                              handleChange={e =>
                                setFieldValue('question_meta.option_filter', e)
                              }
                              onBlur={handleBlur}
                              error={
                                !!(errors?.question_meta as any)?.option_filter
                              }
                              helperText={
                                (errors?.question_meta as any)?.option_filter
                              }
                            />
                          </Grid>
                        )}
                      </>
                    );
                  if (input === 'is_multiple')
                    return (
                      <Grid item mobile={12}>
                        <LBTSelect
                          id="is_multiple-select"
                          name="is_multiple"
                          value={Number(values.question_meta?.is_multiple ?? 0)}
                          label="Multiplo"
                          items={[
                            { id: 1, name: 'Sì' },
                            { id: 0, name: 'No' },
                          ]}
                          handleChange={e => {
                            setFieldValue('question_meta.is_multiple', !!e);
                          }}
                          required
                          onBlur={handleBlur}
                          error={!!(errors?.question_meta as any)?.is_multiple}
                          helperText={
                            (errors?.question_meta as any)?.is_multiple
                          }
                        />
                      </Grid>
                    );
                  if (input === 'has_option_description')
                    return (
                      <Grid item mobile={12}>
                        <LBTSelect
                          id="has_option_description-select"
                          name="has_option_description"
                          value={Number(
                            values.question_meta?.has_option_description ?? 0,
                          )}
                          label="Opzioni con descrizione"
                          items={[
                            { id: 1, name: 'Sì' },
                            { id: 0, name: 'No' },
                          ]}
                          handleChange={e => {
                            setFieldValue(
                              'question_meta.has_option_description',
                              !!e,
                            );
                          }}
                          required
                          onBlur={handleBlur}
                          error={
                            !!(errors?.question_meta as any)
                              ?.has_option_description
                          }
                          helperText={
                            (errors?.question_meta as any)
                              ?.has_option_description
                          }
                        />
                      </Grid>
                    );
                  if (input === 'has_autocomplete')
                    return (
                      <Grid item mobile={12}>
                        <LBTSelect
                          id="has_autocomplete-select"
                          name="has_autocomplete"
                          value={Number(
                            values.question_meta?.has_autocomplete ?? 0,
                          )}
                          label="Auto completamento"
                          items={[
                            { id: 1, name: 'Sì' },
                            { id: 0, name: 'No' },
                          ]}
                          handleChange={e => {
                            setFieldValue(
                              'question_meta.has_autocomplete',
                              !!e,
                            );
                          }}
                          required
                          onBlur={handleBlur}
                          error={
                            !!(errors?.question_meta as any)?.has_autocomplete
                          }
                          helperText={
                            (errors?.question_meta as any)?.has_autocomplete
                          }
                        />
                      </Grid>
                    );
                  if (input === 'validation') {
                    const validationOptions =
                      values.question_type === 'text'
                        ? VALIDATION_TEXT
                        : VALIDATION_DATE;
                    return (
                      <Grid item mobile={12}>
                        <LBTSelect
                          id="validation-select"
                          name="validation"
                          value={values.question_meta?.validation}
                          label="Validazione"
                          items={
                            validationOptions.map(type => ({
                              id: type,
                              name: LABEL_VALIDATION[type],
                            })) ?? []
                          }
                          handleChange={e =>
                            setFieldValue('question_meta.validation', e)
                          }
                          onBlur={handleBlur}
                          error={!!(errors?.question_meta as any)?.validation}
                          helperText={
                            (errors?.question_meta as any)?.validation
                          }
                        />
                      </Grid>
                    );
                  }
                })}
              </>
            )}
          </>
        );
      }}
    </ModalForm>
  );
}
