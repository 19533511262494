import {
  QuestionFlow,
  QuestionFlowClass,
  QuestionFlowGraphItem,
} from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const questionFlowState = atom<QuestionFlow>({
  key: getUUID(),
  default: new QuestionFlowClass(),
});

export const questionFlowsState = atom<QuestionFlow[]>({
  key: getUUID(),
  default: [],
});

export const questionFlowGraphState = atom<QuestionFlowGraphItem[]>({
  key: getUUID(),
  default: [],
});

export const questionFlowLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const questionFlowsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const questionFlowGraphLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
