import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  CheckboxStyle,
  IconArrowDownComponent,
  IconCrossComponent,
  IconWarningComponent,
  IconsSize,
  IconsStyle,
  LBTCheckbox,
  LBTListItem,
  LBTProgressSpinner,
  LBTTextField,
} from '../..';
import { COLORS } from '../../utils/Colors';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  SelectProps,
  Stack,
} from '@mui/material';
import LBTChip from '../Chip';
import LBTLabel from '../Label';

type ItemType = {
  description?: string;
  id: number | string | null;
  name: number | string;
  [x: string]: any;
};

export type LBTAutocompleteProps = SelectProps & {
  items: ItemType[];
  handleChange: (e: any) => void;
  hasFullWidth?: boolean;
  helperText?: string;
  datatestid?: string;
  getItems: (name: string) => Promise<void>;
  minInputLenForFetch?: number;
  isLoading?: boolean;
};

const StyledAutocomplete = styled(Autocomplete<ItemType>)<
  SelectProps & {
    helperText?: string;
    datatestid?: string;
    'data-track': string;
  }
>(({ helperText, datatestid, ...props }) => ({
  helperText,
  datatestid,
  'data-track': props['data-track'],
  '& .MuiAutocomplete-root': {
    height: '52px',
  },
}));

const LBTAutocomplete: React.FC<LBTAutocompleteProps> = ({
  items,
  handleChange,
  helperText,
  datatestid = '',
  variant = 'outlined',
  value,
  error,
  label,
  required,
  multiple,
  disabled,
  hasFullWidth = true,
  minInputLenForFetch = 2,
  getItems,
  isLoading,
  ...props
}: LBTAutocompleteProps) => {
  const [inputText, setInputText] = useState<string>(
    (items.find(item => item.id === value)?.name as string | undefined) ?? '',
  );
  const shrink =
    !!inputText ||
    (multiple
      ? !!(value as any[])?.length && (value as any[]).length > 0
      : value !== null && value !== undefined);
  const currentItem =
    value !== null && value !== undefined
      ? items.find(item => item.id === value)
      : null;

  useEffect(() => {
    if (inputText.length >= minInputLenForFetch) getItems(inputText);
  }, [inputText]);

  useEffect(() => {
    if (value)
      setInputText(
        (items.find(item => item.id === value)?.name as string | undefined) ??
          '',
      );
  }, [value]);

  return (
    <FormControl fullWidth={hasFullWidth}>
      <InputLabel required={required} shrink={shrink}>
        {label}
      </InputLabel>
      <StyledAutocomplete
        size="small"
        options={[
          ...(!multiple && !required
            ? [{ name: '-', label: '-', id: null, desciption: undefined }]
            : []),
          ...items.map(item => ({
            name: item.name,
            label: item.name,
            id: item.id,
            desciption: item.desctiption,
          })),
        ]}
        onChange={(event: any, newValue: any) => {
          handleChange(newValue?.id);
          setInputText(newValue?.name ?? '');
        }}
        onClose={() =>
          setInputText(
            (items.find(item => item.id === value)?.name as
              | string
              | undefined) ?? '',
          )
        }
        inputValue={inputText}
        value={
          currentItem
            ? {
                name: currentItem.name,
                label: currentItem.name,
                id: currentItem.id,
                desciption: currentItem.description,
              }
            : undefined
        }
        fullWidth
        popupIcon={
          <IconArrowDownComponent
            size={IconsSize.MEDIUM}
            style={IconsStyle.OUTLINE}
            color={COLORS.getInstance().BLACK}
          />
        }
        renderValue={(selected: any) => {
          return multiple ? (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((chip: any, index: number) => (
                <LBTChip
                  key={index}
                  label={items.find(item => item.id === chip)?.label}
                  color="default"
                  onDelete={() => {
                    const array = value as Array<any>;
                    handleChange(array.filter(item => item !== chip));
                  }}
                  deleteIcon={
                    <div onMouseDown={event => event.stopPropagation()}>
                      <IconCrossComponent
                        size={IconsSize.SMALL}
                        style={IconsStyle.OUTLINE}
                        color={COLORS.getInstance().BLACK}
                      />
                    </div>
                  }
                />
              ))}
            </Stack>
          ) : (
            <LBTLabel
              variant="inputFormLabel"
              component="label"
              textAlign="left"
            >
              {items.find(item => item.id === selected)?.label ?? ''}
            </LBTLabel>
          );
        }}
        datatestid={`lbt-text-field-${datatestid}`}
        data-track={datatestid}
        multiple={multiple}
        style={{
          backgroundColor: disabled
            ? COLORS.getInstance().BW_GREYS_IPERLIGHT
            : undefined,
          height: 'auto',
        }}
        disabled={disabled}
        renderOption={(props, option) => {
          if (isLoading) {
            if (((props as any)['data-option-index'] as number) === 0)
              return (
                <LBTProgressSpinner
                  sx={{ margin: '8px', marginLeft: '20px' }}
                  size={30}
                  thickness={4}
                />
              );
            return null;
          }
          if (inputText.length < minInputLenForFetch) {
            if (((props as any)['data-option-index'] as number) === 0)
              return (
                <LBTListItem
                  title={`inserisci almeno ${minInputLenForFetch} caratteri per cercare`}
                  titleStyle={{ textWrap: 'wrap' }}
                  descriptionStyle={{ textWrap: 'wrap' }}
                  descrptionComponent="small"
                  sx={{ marginLeft: '20px' }}
                />
              );
            return null;
          }
          return (
            <MenuItem key={option.id} value={option.id} {...props}>
              {multiple && Array.isArray(value) && (
                <LBTCheckbox
                  variant={CheckboxStyle.PRIMARY}
                  checked={value.indexOf(option.id) > -1}
                  sx={{ marginRight: '18px', padding: '11px' }}
                  onChange={() => {}}
                />
              )}
              <LBTListItem
                title={option?.name + ''}
                description={option.description}
                titleStyle={{ textWrap: 'wrap' }}
                descriptionStyle={{ textWrap: 'wrap' }}
                descrptionComponent="small"
              />
            </MenuItem>
          );
        }}
        renderInput={params => (
          <LBTTextField
            {...params}
            value={inputText}
            onChange={str => setInputText(str ?? '')}
            InputLabelProps={{ sx: { display: 'none' }, shrink }}
            variant={variant}
            label={label}
            required={required}
            sx={{
              '& .MuiAutocomplete-endAdornment .MuiIconButton-root': {
                width: 32,
                height: 32,
                marginRight: '1px',
              },
            }}
          />
        )}
        {...props}
      />
      {helperText && (
        <FormHelperText>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {error && (
              <IconWarningComponent
                size={IconsSize.MEDIUM}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().ERROR_MAIN}
              />
            )}
            <LBTLabel
              color={
                error
                  ? COLORS.getInstance().ERROR_MAIN
                  : COLORS.getInstance().BW_GREYS_MEDIUM
              }
              variant="inputHelper"
              component="label"
              style={{ textAlign: 'left' }}
            >
              {helperText}
            </LBTLabel>
          </Box>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default LBTAutocomplete;
