import { ColDef } from 'ag-grid-community';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { useGrid } from '../hooks/useGrid';
import {
  measureRulesState,
  getAllMeasureRulesCallback,
  deleteMeasureRuleCallback,
  MeasureRule,
} from '@laborability/commons';
import { useEffect } from 'react';
import { appBarState } from '../recoil/states/appBar';
import { useNavigate, useParams } from 'react-router-dom';
import { LBTButton } from '@laborability/components';
import { MeasureRuleForm } from '../components/Forms';

export function MisureConditionPage() {
  const { id: measure_id } = useParams();
  const navigate = useNavigate();
  const getAllMeasureRules = useRecoilCallback(getAllMeasureRulesCallback, []);
  const deleteMeasureRules = useRecoilCallback(deleteMeasureRuleCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<MeasureRule>({
    state: measureRulesState,
    onFetch: async () => {
      if (measure_id) return await getAllMeasureRules({ id: measure_id });
    },
    onDelete: async ({ id }) => {
      if (measure_id)
        return await deleteMeasureRules({ measure_id, rule_id: id });
    },
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
  ];

  useEffect(() => {
    setAppBarName('Registrazione regole misure');
  }, []);

  return (
    <GridLayout<any>
      pageName="Regole misura"
      rowData={rowData}
      columnDefs={columnDefs}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={MeasureRuleForm}
      recordNameAccessor={item => item.name ?? ''}
      hasAddNew={!rowData.length}
      gridFilters={
        <LBTButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(-1)}
        >
          Indietro
        </LBTButton>
      }
    />
  );
}
