import { AlertTitle } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { COLORS, FONTS } from '../../utils';
import { IconsSize, IconsStyle } from '../../enums';
import LBTLabel from '../Label';
import {
  IconWarningComponent,
  IconHappyFaceComponent,
  IconInfoComponent,
  IconLightbulb,
  IconCompass,
  IconMoneyFaceComponent,
  IconSofaComponent,
} from '../Icons';

const StyledAlert = styled(Alert)<
  AlertProps & {
    borderRadius?: string;
    backgroundColor?: string;
    borderColor?: string;
    customCloseComponent?: React.ReactNode;
    datatestid: string;
    'data-track': string;
  }
>(
  ({
    theme,
    borderRadius = '15px',
    variant,
    severity,
    datatestid,
    ...props
  }) => ({
    maxWidth: '504px',
    borderRadius,
    datatestid,
    'data-track': props['data-track'],
  }),
);

export type LBTAlertProps = Omit<AlertProps, 'icon' | 'severity'> & {
  title: string;
  message: string;
  onClose?: () => void;
  customCloseComponent?: React.ReactNode;
  datatestid?: string;
};

export default function LBTAlert({
  title,
  message,
  variant = 'contained',
  datatestid = '',
  onClose,
  customCloseComponent,
  color = 'info',
  ...props
}: LBTAlertProps) {
  const getIcon = () => {
    switch (color) {
      case 'success':
        return (
          <IconHappyFaceComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
          />
        );
      case 'info':
        return (
          <IconInfoComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
          />
        );
      case 'error':
      case 'warning':
        return (
          <IconWarningComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
          />
        );
      case 'all_incomes':
        return (
          <IconMoneyFaceComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
            color={COLORS.getInstance().PRIMARY_SUPERDARK}
          />
        );
      case 'online_or_phone':
        return (
          <IconSofaComponent
            size={IconsSize.LARGE}
            style={IconsStyle.OUTLINE}
            color={COLORS.getInstance().PRIMARY_SUPERDARK}
          />
        );
      case 'entertainment':
        return <IconLightbulb size={IconsSize.LARGE} />;
      case 'help':
        return <IconCompass size={IconsSize.LARGE} />;
    }
  };

  return (
    <StyledAlert
      data-track={`lbt-alert-${datatestid}`}
      datatestid={`lbt-alert-${datatestid}`}
      variant={variant}
      color={color}
      icon={getIcon()}
      onClose={onClose}
      action={customCloseComponent}
      style={{
        textAlign: 'left',
      }}
      {...props}
    >
      <AlertTitle
        fontFamily={FONTS.SOURCE_SANS_PRO_BOLD}
        fontSize="17px"
        lineHeight="150%"
        letterSpacing="2%"
        component="strong"
      >
        {title}
      </AlertTitle>
      <LBTLabel color={'inherit'} variant="alertDescription" textAlign="left">
        {message}
      </LBTLabel>
    </StyledAlert>
  );
}
